<template>
  <div class="my-auto w-full max-w-2xl mx-auto">
    <container>
      <transition
        appear
        appear-active-class="appear-from"
        appear-to-class="appear-to"
      >
        <div>
          <p class="text-center mb-8">
            <img src="/img/icon-error.svg" class="w-24 h-24 mx-auto" alt="" />
          </p>
          <h1
            class="text-2xl sm:text-3xl font-headings text-center mb-12"
            v-text="$t('title.error')"
          />

          <p v-text="$t('page.error.text')" />
        </div>
      </transition>
    </container>
  </div>
</template>

<script>
import Container from "../components/Ui/Container.vue";
import { currentDomain } from "../domains";
export default {
  name: "Error",
  components: { Container },
  metaInfo() {
    return { title: this.$t("title.error") };
  },
  created() {
    this.trackEvent();
  },
  methods: {
    trackEvent() {
      this.$gtag.event("conversion", {
        send_to: currentDomain().googleConversionId,
        transaction_id: "",
      });
    },
  },
};
</script>
